import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Icon, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckmarkCircle } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ana Sayfa | DriveNow – Güvenle Sürüş Yapın
			</title>
			<meta name={"description"} content={"DriveNow'ı keşfedin, yüksek kaliteli araç kiralama hizmetleri için. Günlük kiralamalardan uzun dönem kiralamalara kadar, geniş araç yelpazemiz ve mükemmel müşteri hizmetlerimiz ile sizi bekliyoruz. Bugün aracınızı bizden rezerve edin!\n"} />
			<meta property={"og:title"} content={"Ana Sayfa | DriveNow – Güvenle Sürüş Yapın"} />
			<meta property={"og:description"} content={"DriveNow'ı keşfedin, yüksek kaliteli araç kiralama hizmetleri için. Günlük kiralamalardan uzun dönem kiralamalara kadar, geniş araç yelpazemiz ve mükemmel müşteri hizmetlerimiz ile sizi bekliyoruz. Bugün aracınızı bizden rezerve edin!\n"} />
			<link rel={"shortcut icon"} href={"https://kz-wintograce.com/img/537457745.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://kz-wintograce.com/img/537457745.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://kz-wintograce.com/img/537457745.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://kz-wintograce.com/img/537457745.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://kz-wintograce.com/img/537457745.png"} />
			<meta name={"msapplication-TileImage"} content={"https://kz-wintograce.com/img/537457745.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://kz-wintograce.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="normal 900 82px/1.2 --fontFamily-mono"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					DriveNow
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--headline3"
					lg-text-align="center"
					text-align="center"
				>
					Arabanızı Rezerve Edin
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					hover-color="--dark"
					background="--color-primary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-light"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Şimdi Rezervasyon Yapın
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="rgba(0, 119, 204, 0.17)"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						DriveNow'ı Keşfedin
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="--greyD3" md-text-align="center">
						DriveNow'a hoş geldiniz, araç kiralama hizmetleri için başlıca varış noktanız. Tüm ulaşım ihtiyaçlarınız için kesintisiz ve keyifli bir deneyim sağlamayı taahhüt ediyoruz. İster hafta sonu gezisi, ister iş seyahati planlayın veya uzun süreli bir araca ihtiyacınız olsun, sizin için mükemmel bir aracımız var.
						<br />
						<br />
						Geniş araç filomuzda kompakt arabalardan lüks SUV'lara kadar çeşitli araçlar bulunmaktadır. Her araç güvenilirlik ve güvenliği sağlamak için titizlikle bakımdan geçirilir, böylece gönül rahatlığıyla yolculuk yapabilirsiniz. DriveNow'da müşteri memnuniyeti önceliğimizdir ve kiralama deneyiminizi olabildiğince rahat hale getirmeye kararlıyız.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 0 0" quarkly-title="Content-6">
			<Override
				slot="SectionContent"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				width="100%"
				min-width="100%"
				lg-grid-template-columns="1fr"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				background="rgba(0, 0, 0, 0) url(https://kz-wintograce.com/img/2.jpg) 50% 50% /cover repeat scroll padding-box"
				lg-height="600px"
				md-height="500px"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="column"
				padding="80px 60px 80px 60px"
				sm-padding="50px 25px 80px 25px"
			>
				<Text margin="0px 0px 10px 0px" font="normal 400 18px/1.5 --fontFamily-sans" color="--green">
					DriveNow
				</Text>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					Neden DriveNow'ı Tercih Etmelisiniz?
				</Text>
				<Text margin="0px 0px 25px 0px" font="normal 300 22px/1.5 --fontFamily-sans" color="#505257">
					DriveNow'ı tercih etmek, olağanüstü hizmet ve kaliteyi tercih etmek demektir. Araç kiralama sektöründe birkaç nedenden dolayı öne çıkıyoruz:
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						<Strong>
							Çeşitli Araç Filosu:{" "}
						</Strong>
						filomuzda ekonomik arabalardan geniş aile SUV'larına ve şık lüks arabalara kadar çeşitli araçlar bulunmaktadır, ihtiyaçlarınızı karşılayan bir araç mutlaka vardır.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						<Strong>
							Rekabetçi Fiyatlar:{" "}
						</Strong>
						uygun fiyatlar ve esnek kiralama seçenekleri sunuyoruz, böylece paranızın karşılığını en iyi şekilde alırsınız.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						<Strong>
							Kolay Rezervasyon Süreci:{" "}
						</Strong>
						online rezervasyon sistemimiz kullanışlı ve verimlidir, böylece aracınızı hızlı ve kolay bir şekilde rezerve edebilirsiniz.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						<Strong>
							24/7 Müşteri Desteği:{" "}
						</Strong>
						özel müşteri destek ekibimiz, sorularınıza veya sorunlarınıza yardımcı olmak için 7/24 hizmetinizde.
					</Text>
				</Box>
				<Box text-align="center">
					<Text margin="0px 0px 25px 0px" font="normal 300 22px/1.5 --fontFamily-sans" color="#505257" text-align="left">
						DriveNow farkını hissedin ve sorunsuz araç kiralama sürecinin keyfini çıkarın.
					</Text>
					<Link
						href="/contacts"
						padding="12px 24px 12px 24px"
						color="--light"
						background="--color-primary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 0px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-primary"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						hover-transition="background-color 0.2s ease-in-out 0s"
						max-width="200px"
						text-align="center"
					>
						Rezervasyon Yap
					</Link>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65201646cd81ee001f7ce215"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});